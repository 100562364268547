<template>
  <div v-if="state == availStates.START && !isMobile" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <div class="field is-grouped">
        <p class="control">
          <button class="button is-medium is-primary" @click="startAuth(false)">Mobilt BankID</button>
        </p>
      </div>
      <div class="field is-grouped">
        <p class="control">
          <button class="button is-medium is-primary" @click="startAuth(true)">BankID på den här datorn</button>
        </p>
      </div>
    </div>
  </div>
  <div v-else-if="state == availStates.START && isMobile" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <div class="field is-grouped">
        <p class="control">
          <button class="button is-medium is-primary is-fullwidth" @click="startAuth(true)">BankID på den här enheten</button>
        </p>
      </div>
      <div class="field is-grouped">
        <p class="control">
          <button class="button is-medium is-primary is-fullwidth" @click="startAuth(false)">BankID på en annan enhet</button>
        </p>
      </div>
    </div>
  </div>
  <div v-else-if="state == availStates.PROVIDE_PERSONNUMBER" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <div class="field is-bankid-field" v-bind:class="{ 'has-input': bankIdFocus }">
        <label class="label">Personnummer</label>
        <div class="control has-icons-right">
          <input v-model="personNumberInput" v-bind:class="{ 'is-success': validPersonNumber, 'is-danger': showIsError }" v-on:focus="bankIdFocus = true" v-on:blur="bankIdFocus = (personNumber !== '')" v-on:keyup.enter="startAuth(false, personNumber)" :maxlength="13" id="personNumber" type="text" class="input" required />
          <span class="icon is-right">
            <img class="icon-bankid" src="@/assets/bankid.svg">
          </span>
        </div>
        <p v-if="showIsError" class="help is-danger">Ogiltigt personnummer</p>
      </div>
      <div class="field">
        <div class="control">
          <button class="button is-primary is-fullwidth" @click="startAuth(false, personNumber)">Fortsätt</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="state == availStates.PENDING">
    <div class="progress"></div>
    <div class="info-box">
      <div class="columns">
        <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
          <a class="bankid-text" :href="autoStartTokenUrl"><span class="icon"><img src="@/assets/bankid.svg"></span> <span>{{ message }}</span></a>
        </div>
      </div>
    </div>
    <iframe :src="autoStartTokenUrl" style="height: 0;" />
  </div>
  <div v-else-if="state == availStates.PENDING_SHOW_QR">
    <div class="progress"></div>
    <div class="info-box">
      <div class="columns">
        <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
          <span class="bankid-text"><span class="icon"><img src="@/assets/bankid.svg"></span> <span>{{ message }}</span></span>
        </div>
      </div>
      <div class="columns">
        <div class="has-text-centered column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
          <img :src="autoStartTokenQrImage">
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="state == availStates.FAILED" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <b-message title="Fel vid inloggning" type="is-danger" :closable="false">
        {{ message }}
      </b-message>
    </div>
  </div>
  <div v-else-if="state == availStates.SUCCESS" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <b-message title="Inloggad" type="is-success" :closable="false">
        {{ message }}
      </b-message>
    </div>
  </div>
</template>

<script>
import isMobile from 'is-mobile'

export default {
  name: 'bank-id-auth',
  props: {
    debug: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: isMobile({ ua: navigator.userAgent, tablet: true })
    },
    pNumber: {
      type: String,
      default: ''
    },
    collectInterval: {
      type: Number,
      default: 2000
    }
  },
  data () {
    return {
      personNumber: this.pNumber,
      isMobile: this.mobile,
      bankIdFocus: false,
      showLoginError: false
    }
  },
  computed: {
    personNumberInput: {
      get: function () {
        return this.personNumber
      },
      set: function (newValue) {
        this.showLoginError = false
        this.personNumber = this.formatPersonal(newValue)
      }
    },
    validPersonNumber: function () {
      return this.validPersonal(this.personNumber)
    },
    showIsError: {
      get: function () {
        if (this.validPersonNumber) return false
        return this.showLoginError
      },
      set: function (newValue) {
        this.showLoginError = newValue
      }
    },
    availStates: function () {
      return this.$store.state.session.availStates
    },
    state: function () {
      return this.$store.state.session.authState
    },
    message: function () {
      return this.$store.state.session.message
    },
    autoStartToken: function () {
      return this.$store.state.session.autoStartToken
    },
    autoStartTokenUrl: function () {
      return this.$store.state.session.autoStartTokenUrl
    },
    autoStartTokenQrImage: function () {
      return this.$store.state.session.autoStartTokenQrImage
    }
  },
  methods: {
    startAuth (thisDevice, personNumber = null) {
      if (personNumber !== null && !this.validPersonNumber) {
        this.showIsError = true
        return
      }
      var act = (personNumber === '190101011234') ? 'fakeLogin' : 'startAuth'
      this.$store.dispatch(act, { thisDevice, personNumber, collectInterval: this.collectInterval, mobile: this.mobile, debug: this.debug })
    },
    formatPersonal (pn) {
      if (pn == null) return null
      return pn.replace('-', '')
    },
    validPersonal (pn) {
      if (pn == null) return false
      pn = pn.replace(/[^0-9]|[-]/g, '').toString()
      if (!pn.match(/[0-9]{12}/)) return false
      if (!pn.match(/([0-9][0-9]).{8}/)) return false
      if (!pn.match(/.{2}(0[1-9]|1[0-2]).{6}/)) return false
      if (!pn.match(/.{4}(0[1-9]|[1-2][0-9]|3[0-1]).{4}/)) return false
      return true
    }
  },
  watch: {
    '$store.getters.isLoggedIn' (newState, oldState) {
      if (this.$store.getters.isLoggedIn && !this.debug) {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  $deep-sapphire: #012169;
  $lochmara: #0085CA;
  .is-bankid-field {
    position: relative;
    label {
      position: absolute;
      top: 20px;
      left: 14px;
      z-index: 90;
      font-size: 1.2rem;
      margin: 0;
      pointer-events: none;
      transform-origin: top left;
      transition: transform 150ms ease;
      font-weight: 400;
    }
    &.has-input {
      label {
         transform: scale(0.65) translateY(-15px);
      }
    }
    input {
      padding: 1.6rem 0.8rem 0.8rem 0.8rem;
      height: auto;
      font-size: 1.2rem;
      &::-ms-clear {
        display: none;
      }
    }
    .icon {
      width: 2.85em;
      height: 100%;
      &.is-right {
        right: 5px;
      }
    }
  }
  .icon-bankid {
    height: 1.5rem;
  }

  .button.is-fullwidth {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    height: auto;
  }
  .control .button.is-primary {
    background-color: $deep-sapphire;
  }

  .progress {
    height: 1rem;
    width: 100%;
    background: #333;
    border-radius: 0px;
    background: radial-gradient(circle at center, $lochmara 0, $deep-sapphire 100%) repeat center center;
    margin-bottom: 0px;
    animation: progress 5s linear infinite;
    @media screen and (max-width: 768px) {
      animation: progress-full-width 5s linear infinite;
    }
  }
  .info-box {
    padding: 3rem 0;
    background: #f6fcff;
    .bankid-text {
      color: $deep-sapphire;
      display: flex;
      align-items: center;
      font-weight: 800;
      font-size: 0.9rem;
      span {
        font-family: "Nexa", sans-serif;
      }
      .icon {
        margin-right: 0.7rem;
        width: 2em;
        height: 2em;
      }
    }
  }
  @keyframes progress {
    from { background-position: 0 0; }
    to { background-position: 50vw 0; }
  }
  @keyframes progress-full-width {
    from { background-position: 0 0; }
    to { background-position: 100vw 0; }
  }
</style>
