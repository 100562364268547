<template>
  <div class="sparande">
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Du är nu utloggad
          </h1>
          <h2 class="subtitle">
            Välkommen åter
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'logout',
  beforeCreate: function () {
    this.$session.destroy()
  }
}
</script>
